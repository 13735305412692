import { Button } from '@chakra-ui/button'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/react'
import IpeLogo from '@components/atoms/ipe-logo/ipe-logo.atom'
import { useSupabase } from '@hooks/useSupabase.hook'
import { signInWithPassword } from '@services/auth/auth.service'
import { forgotPassword } from '@services/users/users.service'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PasswordInput } from 'src/components/molecules/password-input/password-input.comp'
import { LoginFormValues } from './login.types'

const LoginTemplate = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormValues>({ defaultValues: { password: '' } })

  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false)
  const { error } = useSupabase()
  const toast = useToast()
  const router = useRouter()

  useEffect(() => {
    if (error) {
      toast({ title: error?.title, description: error?.message, status: 'error', position: 'top' })
    }
  }, [error, toast])

  async function handleSignIn(values: LoginFormValues) {
    try {
      await signInWithPassword(values)
      router.replace('/')
    } catch (err) {
      const cause = err as Error
      toast({
        title: cause?.name ?? 'Erro',
        description: cause?.message ?? 'Impossivel de realizar o login',
        status: 'error',
      })
    }
  }

  async function handleForgotPassword(values: LoginFormValues) {
    try {
      await forgotPassword(values.email)
      toast({
        title: 'Email enviado com sucesso.',
        description: 'Verifique a caixa de email para prosseguir com a recuperação da palavra-passe.',
        status: 'success',
        position: 'top',
      })
    } catch (error) {
      toast({
        title: 'Não foi possível completar sua solicitação.',
        status: 'error',
        position: 'top',
      })
    }
  }

  return (
    <Flex height={'100vh'} alignItems="center" justifyContent="flex-start" bg="gray.200" flexDirection="column" pt={12}>
      <Box mb={10}>
        <IpeLogo color="purple.500" boxSize={[100, 150, 200, 250]} />
      </Box>
      <Box boxShadow="lg">
        <form onSubmit={handleSubmit(handleSignIn, (e) => console.error(e))}>
          <Flex direction="column" alignItems="center" background="white" p={8}>
            {showForgotPassword ? (
              <Box width={300} mb={4}>
                <Text as="i" textAlign="justify">
                  Para recuperar o vosso acesso, por favor insira o endereço de email utilizado na plataforma e, dentro
                  de instantes, enviaremos um email para redefinição da palavra-passe.
                </Text>
              </Box>
            ) : (
              <Box width={300} mb={6}>
                <Text as="i" textAlign="justify">
                  Conheça mais sobre a plataforma em <strong>https://ipe.gve.pt/</strong> ou{' '}
                  <a href="https://ipe.gve.pt/" target="_blank" style={{ textDecoration: 'underline' }}>
                    clique aqui
                  </a>
                  .
                </Text>
              </Box>
            )}
            <FormControl mb={4} isInvalid={errors.email != null}>
              <FormLabel>Email de utilizador:</FormLabel>
              <Input
                placeholder="Insira o seu email de utilizador"
                type="email"
                {...register('email', { required: { value: true, message: 'É necessário fornecer um email' } })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            {!showForgotPassword && (
              <FormControl isInvalid={errors.password != null}>
                <FormLabel>Palavra-passe:</FormLabel>
                <PasswordInput {...control} />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            )}
            <Flex direction="row" justifyContent="end" mt={2} mb={4} width="100%">
              <Text
                fontSize="md"
                as="u"
                color="purple.700"
                pointerEvents="all"
                cursor="pointer"
                onClick={() => setShowForgotPassword(!showForgotPassword)}
              >
                {!showForgotPassword ? 'Esqueceu a palavra-passe?' : '< Voltar'}
              </Text>
            </Flex>
            {showForgotPassword ? (
              <Button
                colorScheme="purple"
                width="100%"
                onClick={handleSubmit(handleForgotPassword)}
                isLoading={isSubmitting}
              >
                Enviar e-mail de recuperação
              </Button>
            ) : (
              <Button colorScheme="purple" width="100%" isLoading={isSubmitting} type="submit">
                Aceder
              </Button>
            )}
          </Flex>
        </form>
      </Box>
    </Flex>
  )
}

export default LoginTemplate
